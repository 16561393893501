import './App.css';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';
import {lazy} from "react";

const WealthLevel = lazy(() => import("../src/component/wealth_level/wealth_level"));
const IntegralStore = lazy(() => import("../src/component/integral/integral_store"));
const IntegralRecord = lazy(() => import("../src/component/integral/Integral_record"));
const DownLoad = lazy(() => import("../src/component/other/download"));

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<WealthLevel/>}/>
                    <Route path="/IntegralStore" element={<IntegralStore/>}/>
                    <Route path="/WealthLevel" element={<WealthLevel/>}/>
                    <Route path="/IntegralRecord" element={<IntegralRecord/>}/>
                    <Route path="/DownLoad" element={<DownLoad/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
